import { motion } from "framer-motion";
import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";

const Box = styled.div`
  width: calc(10rem + 15vw);
  height: auto;
  padding: 1rem;
  color: ${(props) => props.theme.text};
  border: 2px solid ${(props) => props.theme.text};
  backdrop-filter: blur(2px);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  z-index: 5;
  border-radius:8px;
  &:hover {
    color: ${(props) => props.theme.body};
    transition: all 0.3s ease;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Input = styled.input`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid ${(props) => props.theme.body};
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  outline: none;
  border-radius:4px;
  &:focus {
    border-color: ${(props) => props.theme.text};
  }
`;

const TextArea = styled.textarea`
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid ${(props) => props.theme.body};
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  resize: none;
  height: 100px;
  border-radius:4px;
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.text};
  }
`;

const SubmitButton = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: ${(props) => props.theme.body};
  background-color: ${(props) => props.theme.text};
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
  &:hover {
    background-color: ${(props) => props.theme.body};
    color: ${(props) => props.theme.text};
  }
`;

const Container = styled(motion.div)``;

const Item = {
  hidden: {
    scale: 0,
  },
  show: {
    scale: 1,
    transition: {
      type: "spring",
      duration: 0.5,
    },
  },
};

const ContactUsComponent = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://api.w3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          access_key: '1b5b5999-3b44-44ea-9159-2d177f4ca302',
          name: formData.name,
          email: formData.email,
          subject: formData.subject,
          message: formData.message,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log('Success:', result);
      alert('Message sent successfully!');
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while sending the message.');
    }
  };
  

  return (
    <Container variants={Item}>
      <Box>
        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            name="name"
            placeholder="Your Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <Input
            type="email"
            name="email"
            placeholder="Your Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <Input
            type="text"
            name="subject"
            placeholder="Subject"
            value={formData.subject}
            onChange={handleChange}
            required
          />
          <TextArea
            name="message"
            placeholder="Message"
            value={formData.message}
            onChange={handleChange}
            required
          />
          <SubmitButton type="submit">Send Message</SubmitButton>
        </Form>
      </Box>
    </Container>
  );
};

export default ContactUsComponent;
