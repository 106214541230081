// work data

export const Work = [
  {
    id: 1,
    name: "ADSTIA AGENCY PVT LTD.",
    description:
      "In the Wecall CMS project, I'm lead a team of five developer a customizable user interface platform with domain deployment capabilities, integrating frontend hosting with CMS features. We used Nginx, BIND9, Node.js, and React, and implemented microservices with 11 repositories, including modules for UI development and dynamic quiz creation.",
    tags: ["nginx", "bind9", "node(18.13)"],
  },
  {
    id: 2,
    name: "ADSTIA AGENCY PVT LTD.",
    description: "Medicare: Built on react hosted using netlify",
    tags: ["react", "scss"],
  },
  {
    id: 3,
    name: "ADSTIA AGENCY PVT LTD.",
    description:
      "Prelanders: Connected with storyblok cms using react app. Daily traffic on website of this project was more than 1lakh in 8hours",
    tags: ["react", "sass", "javascript"],
  },
  {
    id: 4,
    name: "SCORPIUS SOLUTION SDN. BDN. AND LEARN NOW",
    description:
      "In the Startup India project, I used React to visualize data on an India map with color opacity and integrated React Table for displaying startup data from startupindia.gov.in.",
    tags: ["react", "sass", "javascript"],
  },
  {
    id: 5,
    name: "SCORPIUS SOLUTION SDN. BDN. AND LEARN NOW",
    description:
      "For Vigyan Pujyate, I independently developed a project to celebrate India's 75th Independence anniversary, featuring an SVG map with color grading, historical books, and profiles of freedom fighters.",
    tags: ["react", "sass", "javascript"],
  },
  {
    id: 6,
    name: "XCRINO BUSSINESS SOLUTION",
    description:
      "POS Management :- Team were using next js for development. I used bootstrap to develop modals and added forms and their navigation similar to outlook top menu.",
    tags: ["next js", "bootstrap"],
  },
];
